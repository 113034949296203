import config from './config';
// import showToasterMessage from '../containers/UI/ToasterMessage/toasterMessage';
// import apiCalls from './apiCalls';
import axios from 'axios';
import { GetSessionValue, SetSessionValue } from '../utils/sessionStorage';
let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

// To get logged in user token
const getLoggedInuserToken = () => {
    let userData = GetSessionValue('loginCredentials');

    if (userData) {
        headers.Authorization = `Bearer ${userData.accessToken}`;
        headers.logggedInUserData = {
            email: userData.email,
            password: userData.password,
        }
        return headers;
    } else {
        return headers;
    }
}

const axiosMethodRequest = (method, url, body, type) => {

    if (url === 'auth/login') {
        return sendRequestToServer(method, url, body, headers);
    } else if (url === "/client") {
        return getDetailedInfo(url, body, headers)
    } else {
        let headers = getLoggedInuserToken();
        // upload method conditions, headers
        if (type && type == 'upload') {
            let formData = new FormData();
            // let formData={}
            if (body && body.length && body.length > 0) {
                body.forEach(element => {
                    formData.append('file', element);
                });
            } else if (body) {

                formData.append('file', body);
                // formData.file=body
            }
            if (headers.logggedInUserData) {
                delete headers.logggedInUserData;
            }
            body = {
                isUploadingImage: true,
                imageInfo: formData,
            }
        }

        return sendRequestToServer(method, url, body, headers)
    }
}

const sendRequestToServer = (method, url, body, headers) => {
    let reqHeaders = { ...headers };

    if (reqHeaders && reqHeaders.logggedInUserData) {
        delete reqHeaders.logggedInUserData;
        delete reqHeaders.accept
    }
    let isImageReqSent = false;
    let request;

    if (body && body.isUploadingImage) { // only for image upload
        isImageReqSent = true;
        if (method == 'POST') {
            let modifiedHeaders = {
                // 'accept':'*/*',
                'Accept': '*/*',
                'Authorization': `${reqHeaders.Authorization}`,
            }
            return request = axios.post(`${config.apiUrl}${url}`, body.imageInfo, {
                headers: modifiedHeaders,
            })
        } else if (method == 'PUT') {

        }
        // request = fetch(`${config.apiUrl}${url}`, {
        //     method: method,
        //     headers: {
        //         'Authorization': `${reqHeaders.Authorization}`
        //     },
        //     body: body.imageInfo
        // })

    }

    if (!isImageReqSent) { // send request for call except image upload 
        if (method === 'DELETE') {
            // request = fetch(`${config.apiUrl}${url}`, { method: method, headers: reqHeaders })
            request = axios.delete(`${config.apiUrl}${url}`, {
                headers: reqHeaders,
            })
            return request
        } else if (method === 'GET') {
            request = axios.get(`${config.apiUrl}${url}`, {
                headers: reqHeaders,
            })
            let modifiedRequest = request

            modifiedRequest.then((res) => {
                if (res && res.data && res.data.errorCode && res.data.errorMessage && res.data.errorMessage == "Session expired, please login again.") {
                    SetSessionValue('sessionExpired', true)
                }

            })
            return request
        }
        else if (method === 'PUT') {
            request = axios.put(`${config.apiUrl}${url}`, body, {
                headers: reqHeaders,
            })
            return request
        } else if (method === 'POST') {
            request = axios.post(`${config.apiUrl}${url}`, body, {
                headers: reqHeaders,
            })
            return request
        }
    }


}

export const getDetailedInfo = async (url, request, email) => {
    console.log("exportTransactionApiCall    :   getDetailedInfo    ")
    switch (url) {
        // case "/dashboard":
        //     return getDashBoardData(url,request,headers)
        case "/client":
            return await getDashBoardInfo(url, request, headers)
        case "/export":
            return await exportTxn(email, request, headers)
        default:
            break;
    }
}

export const getDashBoardInfo = (url, body, headers) => {

    let request;
    let endpoint = `${config.dashboardurl}${"transactions/aggregate/client?stage=final&condition_type=or"}`
    let reqHeaders = { ...headers };
    console.log("GetUserDetails     :   " + JSON.stringify(reqHeaders))
    if (reqHeaders && reqHeaders.logggedInUserData) {
        delete reqHeaders.logggedInUserData;
        delete reqHeaders.accept
    }
    request = axios.post(`${endpoint}`, body, {
        headers: {
            ...reqHeaders,
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'access-token': config.dashboardKey,
        }
    })
    return request
}

// export const exportTxn = (url, body, headers) => {
//     let request;
//     let endpoint = "https://api.krowdit.com/transactions/export?stage=final&condition_type=or"
//     let reqHeaders = { ...headers };
//     if (reqHeaders && reqHeaders.logggedInUserData) {
//         delete reqHeaders.logggedInUserData;
//         delete reqHeaders.accept
//     }
//     request = axios.post(`${endpoint}`, body, {
//         headers: reqHeaders,
//     })
//     return request
// }
export const exportTxn = async (email, body, headers) => {

    console.log("getDetailedInfo    exportTxn    ")
    let request;
    const baseUrl = `${config.dashboardurl}${"transactions/export"}`;
    const stage = "final";
    const conditionType = "or";
    const secondEmail = email; // Optional email

    // Encode the emails for URL
    const encodedFirstEmail = encodeURIComponent(email);
    let url = `${baseUrl}?stage=${stage}&CC_EMAIL=${encodedFirstEmail}`;

    if (secondEmail && secondEmail !== "") {
        const encodedSecondEmail = encodeURIComponent(secondEmail);
        url += `&CC_EMAIL=${encodedSecondEmail}`;
    }

    url += `&condition_type=${conditionType}`;
    let reqHeaders = { ...headers };
    console.log(" reqHeaders    :    " + JSON.stringify(reqHeaders))
    if (reqHeaders && reqHeaders.logggedInUserData) {
        delete reqHeaders.logggedInUserData;
        delete reqHeaders.accept
    }
    request = await axios.post(`${url}`, body, {
        headers: {
            ...reqHeaders,
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'access-token': config.dashboardKey,
        }
    })
    let response = {}
    console.log("Email Response  is    :  " + JSON.stringify(request))

    if (request.status === 200 && request.data && request.data.message) {
        response = {
            responseCode: "200",
            responseMessage: "Success",
            data: request.data.message // Extracts the message from the successful response
        };
    } else if (request.data.detail) {
        response = {
            responseCode: "400",
            responseMessage: "Failed",
            data: request.data.detail// Error message for failed email attempts
        };
    }
    console.log("Email Response  is    :  " + JSON.stringify(response))
    return response
}

export default axiosMethodRequest;
